import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Breadcrumb, Skeleton, Tag } from "antd";
import { PaginationProps } from "antd/lib";
import classNames from "classnames";
import { useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import { StrapiClient } from "../../api/instance";
import { ImageListProps, ProductProps } from "../../api/type";
import Price from "../../components/Price";
import { axiosExtract, colorPalette, useQueryNoRefetch, useScrollToSmooth } from "../../utils";
 
type ProductDetailProps = ProductProps & {
	carousel: ImageListProps;
	product_categories: {
		data: Array<{
			id: number;
			attributes: { name: string };
		}>;
	};
};

type ProductsPromiseProps = {
	meta?: { pagination: PaginationProps };
	data: { id: number; attributes: ProductDetailProps };
};

// function generateWhatsAppMessage(location: string) {
// 	return `https://wa.me/3758076882?text=${encodeURI(`Buongiorno sarei interessato a prenotare il seguente prodotto ${location}`)}`;
// }

const back = -1;
const ProductDetails = () => {
	
	useScrollToSmooth(0);
	const navigate = useNavigate();
	const { id } = useParams();
	const location = useLocation();

	const { isLoading, data } = useQueryNoRefetch(["queryProductDetails", id], {
		enabled: id !== undefined,
		queryFn: () => axiosExtract(StrapiClient.get<ProductsPromiseProps>(`/products/${id}?populate=img&populate=carousel&populate=product_categories`)),
		onError: () => navigate(back),
	});
    const { data: product } = data ?? {}

	const sliderConfig = useMemo<Settings>(
		() => ({
			dots: true,
			arrows: false,
			fade: true,
			infinite: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			swipeToSlide: true,
			adaptiveHeight: false,
			autoplay: false,
			className: "h-full [&>div]:!h-full [&>div>div]:h-full [&>div>div>div>div]:h-full",
			dotsClass: classNames(
				"w-24 h-24 lg:w-36 lg:h-36 !flex !gap-2 list-none  mt-2",
				"[&>.slick-active]:border [&>.slick-active]:border-bulrush-400/10 [&>.slick-active]:border [&>.slick-active]:border-solid [&>.slick-active]:shadow",
			),
			customPaging: (i) => {
				return (
					<div
						className={"w-24 lg:w-36 h-full rounded bg-white cursor-pointer"}
						key={i}
					>
						<div className="relative w-full h-full">
							<img
								src={product?.attributes.carousel.data?.[i].attributes.url ?? ""}
								alt={product?.attributes.carousel.data?.[i].attributes.url ?? ""}
								className="object-contain rounded w-full h-full"
								sizes="(max-width: 768px) 100vw"
							/>
						</div>
					</div>
				);
			},
		}),
		[product],
	);

	if (product === null && isLoading) {
		return (
			<section className="m-4 xl:w-11/12 xl:mx-auto">
				<div className="lg:grid lg:grid-cols-5 lg:gap-4 h-[80vh]">
					<div className="lg:col-span-3 mb-8 lg:mb-auto h-full bg-white">
						<Skeleton.Image active={true} className="!w-full !h-full mb-12" />
					</div>
					<div className="lg:col-span-2 relative grid gap-4  grid-rows-3">
						<div className="p-4 bg-white">
							<Skeleton active={true} className="!w-full " />
						</div>
						<div className="bg-white rounded p-4 grid row-span-2">
							<Skeleton active={true} className="!w-full" />
						</div>
					</div>
				</div>
			</section>
		);
	}

	return (
		<section data-role="product details" className="m-4 xl:w-11/12 xl:mx-auto relative">
			<Breadcrumb
				items={[
					{
						path: "/prodotti",
						breadcrumbName: "prodotti",
					},
					{
						path: `/prodotti/${id}`,
						breadcrumbName: (product?.attributes.name as string | undefined) ?? "",
					},
				]}
				className="mb-4 sm:mx-4 md:mx-0"
				itemRender={(r) => (
					<Link to={r.path!} className={r.path === location.pathname ? "font-semibold !text-black" : ""}>
						{r.breadcrumbName}
					</Link>
				)}
			/>
			<div className="md:grid md:grid-cols-5 md:gap-4">
				<div className="md:col-span-3 mb-4 md:mb-auto h-full">
					<Slider {...sliderConfig}>
						{product?.attributes.carousel.data?.map((x, i) => (
							<div className="h-full" key={i}>
								<div
									className={classNames({
										"bg-white flex justify-center items-center h-full": true,
										"min-h-[calc(50vh_-_96px)] lg:min-h-[calc(70vh_-_150px)]": (product?.attributes.carousel.data?.length ?? 0) >= 2,
										"min-h-[50vh]": product?.attributes.carousel.data?.length === 1,
									})}
								>
									<div className="h-3/6 w-3/6 md:relative">
										<img
											src={x?.attributes.url ?? ""}
											alt={x?.attributes.name ?? ""}
											className="object-contain w-full h-full"
											sizes="(max-width: 768px) 100vw"
										/>
									</div>
								</div>
							</div>
						))}
					</Slider>
				</div>
				<div className="md:col-span-2 relative grid gap-4 md:grid-rows-3">
					<div className="bg-white rounded p-4">
						<h2 className="font-manrope">{product?.attributes.name}</h2>
						<div className="flex justify-between items-end">
							<p>Prezzo:</p>
							{product && <Price price={product.attributes.price} discountPrice={product.attributes.discount_price ?? undefined} />}
						</div>

						<div className="flex justify-between items-end">
							<p>Disponibilità:</p>
							<CheckCircleIcon width={24} height={24} color={colorPalette.sacramento} />
						</div>

						<div className="flex justify-between items-end">
							<p>Categorie:</p>
							<div className="flex gap-1">
								{product?.attributes.product_categories.data.map((x, i) => (
									<Tag color="blue" key={i}>
										{x.attributes.name}
									</Tag>
								))}
							</div>
						</div>

					</div>

					{/* <div className="bg-white rounded p-4 grid row-span-2">
						<p>{isLoading ? "caricamento..." : product && product?.attributes.description ? product.attributes.description : ""}</p>
						<div className="flex justify-end items-end">
							<Link
								to={generateWhatsAppMessage(window.location.href)}
								target="_blank"
							>
								<Button>
									<div className="flex justify-end items-center gap-2">
										Prenota il farmaco con WhatsApp
										<div className="relative">
											<img src={WhatsAppLogo} alt="WA icon" width={18} height={18} />
										</div>
									</div>
								</Button>
							</Link>
						</div>
					</div> */}
				</div>
			</div>
		</section>
	);
};

export default ProductDetails;
