"use client";

import { Bars3Icon } from "@heroicons/react/24/outline";
import { Button, Drawer, Menu, MenuProps } from "antd";
import { FC, useState } from "react";

import classNames from "classnames";
import { usePageBrake } from "../../utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RuotesProps } from "../Router/routes";

type NavigationProps = {
	routes: RuotesProps;
};

const Navigation: FC<NavigationProps> = ({ routes }) => {
	const pageRoutes = Object.values(routes);
	const { path, title } = pageRoutes[0];

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const { xl, xs } = usePageBrake(true);
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const mappedMenuProps: MenuProps["items"] = pageRoutes
		.filter(({ hide, appBar }) => !hide && appBar )
		.map(({ path, appBar }) => ({
			label: appBar!.title,
			key: path ?? "/",
		}));

	const onChangeMenu: MenuProps["onClick"] = ({ key }) => navigate(key);
	const handleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
	const onChangeDrawer: MenuProps["onClick"] = (menu) => {
		onChangeMenu(menu);
		handleDrawer();
	};

	return (
		<header className="px-4 py-6 sticky top-0 max-w-[2150px] w-full z-50 bg-opacity-20 backdrop-blur-lg">
			<div
				className={classNames({
					"flex justify-between items-center": true,
					"mb-4": false,
				})}
			>
				<h1 className="">
					<Link title={title} to={path ?? "/"}>
						{title}
					</Link>
				</h1>
				{xl ? (
					<div className="w-[470px]">
						<Menu
							items={mappedMenuProps}
							mode="horizontal"
							onClick={onChangeMenu}
							className="bg-transparent [&>li>.ant-menu-title-content]:text-base font-semibold"
							selectedKeys={[pathname]}
						/>
					</div>
				) : (
					<>
						<Button
							className="flex items-center justify-center"
							onClick={handleDrawer}
							size="large"
						>
							<Bars3Icon width={24} height={24} />
						</Button>
						<Drawer
							title={"Menu"}
							placement="right"
							onClose={handleDrawer}
							open={isDrawerOpen}
							width={xs ? 378 : 320}
						>
							<Menu
								items={mappedMenuProps}
								mode="vertical"
								onClick={onChangeDrawer}
								className="bg-transparent"
								selectedKeys={[pathname]}
							/>
						</Drawer>
					</>
				)}
			</div>
		</header>
	);
};

export default Navigation;