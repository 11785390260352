import { useEffect, useMemo, useState } from "react";
import { useDebounce, useMedia } from "react-use";

const useDisableBodyScroll = (open: boolean) => {
	useEffect(() => {
		if (open) {
			document.body.style.overflow = "hidden";
			document.body.style.height = "100dvh";
		} else {
			document.body.removeAttribute("style");
		}
	}, [open]);
};

const useQuery = <T>(data: T[], interval?: number) => {
	const [query, setQuery] = useState("");
	const [debouncedKey, setDebouncedKey] = useState(query);

	const list = useMemo(() => data.filter((x) => JSON.stringify(x).toLowerCase().includes(debouncedKey.toLowerCase())), [debouncedKey, data]);

	const [isReady, cancel] = useDebounce(
		() => {
			setDebouncedKey(query);
		},
		interval ?? 500,
		[query],
	);

	return { isReady, cancel, list, query, setQuery };
};

type WindowScrollPosition = {
	x: number;
	y: number;
};

const useWindowScroll = (): WindowScrollPosition => {
	const [scrollPosition, setScrollPosition] = useState<WindowScrollPosition>({
		x: 0,
		y: 0,
	});

	const handleScroll = (): void => {
		setScrollPosition({
			x: window.scrollX,
			y: window.scrollY,
		});
	};

	useEffect(() => {
		// Initial scroll position
		setScrollPosition({
			x: window.scrollX,
			y: window.scrollY,
		});

		if (typeof window !== "undefined") {
			window.addEventListener("scroll", handleScroll);

			// Cleanup the event listener on component unmount
			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		}
	}, []); // Only run the effect once during component mount

	return scrollPosition;
};

const usePageBrake = (isServerSidRendering: boolean, media?: string) => {
	const brakes = {
		xs: useMedia("(min-width: 640px)", isServerSidRendering),
		md: useMedia("(min-width: 768px)", isServerSidRendering),
		lg: useMedia("(min-width: 1024px)", isServerSidRendering),
		xl: useMedia("(min-width: 1280px)", isServerSidRendering),
		"2xl": useMedia("(min-width: 15360px)", isServerSidRendering),
	};

	return {
		...brakes,
		custom: useMedia(media ?? "(min-width: 640px)", isServerSidRendering),
	};
};

const useScrollToSmooth = (top: number) => {
	useEffect(() => {
		window.scrollTo({
			top,
			behavior: "smooth",
		});
	}, [top]);
};

export { usePageBrake, useDisableBodyScroll, useQuery, useWindowScroll, useScrollToSmooth };
