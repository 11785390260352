import { CalendarDaysIcon, EnvelopeIcon, InformationCircleIcon, MapPinIcon, PhoneIcon, UserIcon } from "@heroicons/react/24/outline";
import { Button, Form, Input, message } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useState } from "react";
import { StrapiClient } from "../../api/instance";
import hall from "../../assets/images/hall.jpg";
import { useScrollToSmooth } from "../../utils";
import { unpromisify } from "../../utils/promises";

const Contattaci = () => {
	const [form] = Form.useForm();
	const [requiredMark, setRequiredMarkType] = useState<RequiredMark>("optional");
	const [messageApi, contextHolder] = message.useMessage();

	useScrollToSmooth(0)
	const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
		setRequiredMarkType(requiredMarkValue);
	};

	const onFinish = async (data: { username: string; email: string; messaggio: string; object?: string }) => {
		try {
			await StrapiClient.post("/email", {
				name: data.username,
				email: data.email,
				message: data.messaggio,
				object: data.object ?? "",
			});
			await messageApi.open({
				content: "Email inviata!",
				duration: 10,
				type: "success",
			});
			form.resetFields();
		} catch (error) {
			await messageApi.open({
				content: "Qualcosa è andato storto riprova più tardi",
				duration: 10,
				type: "error",
			});
		}
	};

	const onFinishFailed = async (_error: ValidateErrorEntity<unknown>) => {
		await message.error("Errore! Per favore controlla il form prima di premere invia", 5);
	};

	return (
		<>
			{contextHolder}
			<section data-role="contacs" className="md:m-4 contattaci">
				<div className="grid md:grid-cols-2 my-8 md:mt-8 md:mb-0">
					<div className="flex items-center justify-end">
						<div className="mx-auto relative z-20 w-11/12">
							<div className="flex flex-col bg-white rounded-md p-4 h-fit">
								<div className="mb-4">
									<h2>Contattaci</h2>
								</div>
								<div className="mb-4">
									<ul className="list-none grid gap-4 mt-4">
										<li className="flex gap-2">
											<MapPinIcon width={16} height={16} />
											<a href="https://goo.gl/maps/ximwNtRupFvBD4Bg8" className="underline font-semibold" target="_blank" rel="noopener noreferrer">
												Via Gabrio Piola, 11, 20833 Giussano MB
											</a>
										</li>
										<li className="flex gap-2">
											<PhoneIcon width={16} height={16} />
											<a href="tel:+390362850600" className="underline font-semibold">
												+39 036 285 0600
											</a>
										</li>
										<li className="flex gap-2 flex-wrap">
											<CalendarDaysIcon width={16} height={16} />
											<strong>Lunedi</strong>-<strong>Sabato</strong> <span>08-13.00 15-19:30</span>
										</li>
										<li className="flex gap-2 flex-wrap">
											<CalendarDaysIcon width={16} height={16} />
											<strong>Domenica</strong> <span>08-13.00 15-19:30</span>
										</li>
									</ul>
								</div>
								<Form
									form={form}
									layout="vertical"
									initialValues={{ requiredMarkValue: requiredMark }}
									onValuesChange={onRequiredTypeChange}
									requiredMark={requiredMark}
									onFinishFailed={(error) => onFinishFailed(error)}
									onFinish={unpromisify(onFinish)}
									disabled
								>
									<div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-4">
										<div className="col-span-1">
											<Form.Item
												label="Nome"
												name="username"
												rules={[
													{
														required: true,
														message: "inserisci il tuo nome",
													},
												]}
											>
												<Input size="large" placeholder="Inserisci nome" prefix={<UserIcon width={16} height={16} />} allowClear />
											</Form.Item>
										</div>
										<div className="col-span-1">
											<Form.Item
												label="Email"
												name="email"
												rules={[
													{
														required: true,
														message: "inserisci l'e-mail",
														pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
													},
												]}
											>
												<Input size="large" placeholder="Inserisci mail" prefix={<EnvelopeIcon width={16} height={16} />} allowClear />
											</Form.Item>
										</div>
										<div className="md:col-span-2">
											<Form.Item label="Oggetto" name="object">
												<Input size="large" placeholder="Inserisci l'oggetto" prefix={<InformationCircleIcon width={16} height={16} />} allowClear />
											</Form.Item>
										</div>
										<div className="md:col-span-2">
											<Form.Item
												label="Messaggio"
												name="messaggio"
												rules={[
													{
														required: true,
														message: "Insirisci un messaggio",
													},
												]}
											>
												<TextArea rows={5} size="large" placeholder="Inserisci un messaggio" style={{ resize: "none" }} disabled />
											</Form.Item>
										</div>
										<div className="md:col-span-2 flex justify-end">
											<Button htmlType="submit" size="large">
												Invia
											</Button>
										</div>
									</div>
								</Form>
							</div>
						</div>
					</div>
					<div className="hidden md:block">
						<div className="relative sm:h-full h-[75vh]">
							<img src={hall} alt="farmacia" className="object-cover rounded-md w-full h-full" />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Contattaci;
